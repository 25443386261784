import React from 'react';
import SEO from '../../components/seo';
import SimpleHero from '../../components/simple-hero';
import Navigation from '../../components/navigation';

import { Languages } from '../../tools/languages';
import { PrimaryButton } from '../../components/buttons';
import ContactCTA from '../../components/contact-cta';

import PlaceholderImage from '../../../static/images/placeholder.jpg';

const EnglishNotFoundPage = ({ location, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title="404" description="Not found" lang={language} />
      <Navigation transparent />
      <main>
        <SimpleHero image={PlaceholderImage} alt="404">
          <h1>Oops, you're lost</h1>
          <p>
            Feel free to click on the button below and you will be back in the right place in no
            time. Good luck!
          </p>
          <div>
            <PrimaryButton to="/">Bring be back</PrimaryButton>
          </div>
        </SimpleHero>
        <ContactCTA language={language} />
      </main>
    </>
  );
};

export default EnglishNotFoundPage;
